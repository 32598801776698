<template>
  <div>
    <div class="appBar">

      <img @click="routeBack('/', false)"
           style="height: 18px; width: 18px; margin-left: 18px;"
           src="https://gcdn.bionluk.com/site/icon/tansel_mobile_black_back.svg">

      <h1 class="header-title" v-if="userProfile.username">{{userProfile.username}}</h1>
    </div>

    <div style="margin: 0 auto; padding-top: 60px;"></div>


    <div v-if="!loadedPage" class="profile-page-body" >
      <div class="information-body">
        <div class="information-section-1">
          <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
              <div class="information-section-1-loader-avatar profile-loader"></div>
              <div class="information-section-1-loader-username profile-loader"></div>
              <div class="information-section-1-loader-title profile-loader"></div>
              <div class="information-section-1-loader-stars profile-loader"></div>
              <div class="followers-div-loader profile-loader"></div>

          </div>
          <div class="hiddendiv20"></div>
          <div class="line"></div>
          <div class="hiddendiv20"></div>
          <div style="display: flex; justify-content: space-between;">
            <div>
              <div class="general-info-icon-loader profile-loader"></div>
              <div class="general-info-icon-loader profile-loader"></div>
              <div class="general-info-icon-loader profile-loader"></div>
            </div>
            <div>
              <div class="general-info-loader profile-loader"></div>
              <div class="general-info-loader profile-loader"></div>
              <div class="general-info-loader profile-loader"></div>
            </div>


          </div>
        </div>

      </div>
      <div class="profile-section-body">
        <div style="clear: both"></div>
        <div class="mobileTab">
          <div :class="{mobileTabItem: true, active: activeMobileTab === 1}">Hakkında</div>
          <div :class="{mobileTabItem: true, active: activeMobileTab === 2 }">İş ilanları</div>
          <div :class="{mobileTabItem: true, active: activeMobileTab === 3 }">Yorumlar</div>
        </div>

        <div v-if="activeMobileTab === 1">
          <div class="information-section-2" v-if="portfolios.length && loadedPortfolioPage">
            <div class="information-section-2-label">
              <label class="profile-section-label">Portfolyo</label>
              <p :to="'/portfolyo/' + userProfile.username" class="show-all">Tümünü Göster</p>
            </div>
            <div class="portfolio-loader profile-loader"></div>
          </div>
          <div style="height: 10px; background-color: #f4f5f7"></div>
          <div class="information-section-3">
            <label class="profile-section-label">Açıklama</label>
            <div class="information-section-3-description-loader profile-loader"></div>
            <div class="information-section-3-description-loader profile-loader"></div>
            <div class="information-section-3-description-loader profile-loader"></div>
            <div class="information-section-3-loader-separator"></div>
            <label style="font-size: 16px; font-weight: 600;">Eğitim</label>
            <div class="information-section-3-edu-loader profile-loader"></div>
            <div class="information-section-3-cv-loader profile-loader"></div>
          </div>
        </div>
      </div>

    </div>

    <div class="profile-page-body" v-else>
      <div class="information-body">
        <div v-if="user.username === userProfile.username" style="position: absolute;  vertical-align: top; margin-top: 8px; margin-left: 10px;">
          <router-link to="/settings/" class="editButton" style="height: 25px; width: 100px; min-width: 100px; padding: 4px 24px 5px;">Düzenle</router-link>
        </div>
        <div class="information-section-1">
          <div>

            <div style="position: relative;display: inline-flex">
              <img style="width:100px; height: 100px; border-radius: 50%;  border: 5px solid #EAEAEA" :src="userProfile.avatar_url">
<!--              <div v-if="userProfile.seller_level.level" class="user-profile-level" :data-balloon="userProfile.seller_level.level_text"-->
<!--                   data-balloon-pos="up">-->
<!--                <img :src="userProfile.seller_level.level_icon" alt="">-->
<!--              </div>-->
            </div>
            <div style=" font-size: 16px; color: #2d3640; font-weight: 400; margin-top: 14px">{{ userProfile.username }}</div>
            <div style="color: #8b95a1; font-style: italic; font-size: 14px; margin-top: 3px;">{{ userProfile.title }}</div>
            <div v-if="userProfile.sellerRating.commentCount > 0" class="user-info-rating-div">
              <star-rating :rating="userProfile.sellerRating.commentRating"
                           :max-rating="5"
                           :star-size="11"
                           :show-rating="false"
                           inactive-color="#dce1e1"
                           active-color="#ffb700"
                           :read-only="true"></star-rating>
              <p style="font-weight:500;font-size: 14px; color: #ffb700; margin-left: 4px;">{{userProfile.sellerRating.commentRating | convertNumberToFraction}}
                <span style="font-size: 13px; font-weight: 400; color: #8b95a1;"> ({{userProfile.sellerRating.commentCount}})</span></p>
            </div>


            <div class="followers-div" style="margin-bottom: 10px"

                 v-if="userProfile.followerCount > 0">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_follower_profile.svg"/>
              <p class="followers-count">{{ userProfile.likeCount }} kişi listesine ekledi</p>
            </div>

          </div>

          <div class="button-div">

            <button v-if="user.username !== userProfile.username" @click="sendMessage()"
                    class="send-message-button">
              Mesaj Gönder
            </button>
          </div>

          <div v-if="userProfile.f_t_info.isActive">
            <div class="hiddendiv20"></div>
            <div class="line"></div>
            <div class="hiddendiv20"></div>
            <div style=" color: #8b95a1; font-size:14px; width: 100%">
              <div style=" position: absolute; z-index: 999; margin-left: 267px; margin-top: 2px; cursor: help; font-size: 12px; color: #2d3640; font-weight: 300;  opacity: 0.8" data-balloon="Yanda görülen telefon numarası ve dahili numara ile ilgili freelancer'a telefonla ulaşabilirsin. Operatörünün yurt içi sabit hat tarifesinden ücretlendirilirsin. Bu hattın ekstra bir ücreti yoktur."
                   data-balloon-pos="left" data-balloon-length="medium"><p style="text-align: right;">Bu nedir?</p>
              </div>
              <div  style="text-align: left">
                <div>
                  <img src="https://gcdn.bionluk.com/site/icon/ic_phone_in_talk_gray_svg.svg" style="position: absolute;">
                  <span style="padding-left: 35px;">Freelancer Telefon Hattı</span>

                  <div style=" padding-left:35px; text-align: left; margin-top: 12px;">
                    <div style="margin-top: 8px; "><div style="display: inline-block;font-weight: 500;  width: 60px;">Telefon:</div> <div style="display: inline-block; color: #2d3640;">0(212) 909 23 10</div></div>
                    <div style="margin-top: 8px;">

                      <div style="display: inline-block;font-weight: 500; width: 60px;">Dahili:</div>
                      <div v-if="!user.username" style="display: inline-block; font-weight: 300; color: #2d3640;">
                        Görmek için <router-link to="/login" style="color: #fd4056; font-weight: 400;">üye girişi</router-link> yap.
                      </div>
                      <div v-else-if="userProfile.f_t_info.isPublic" style="display: inline-block; color: #2d3640;">{{userProfile.f_t_info.line}}</div>
                      <div v-else style="display: inline-block; color: #2d3640;">
                        <div style=" z-index: 999; cursor: help; font-weight: 300; color: #2d3640;" data-balloon="Freelancer dahili numarasını gizlemeyi tercih ediyor. Kendisine mesaj göndererek telefonla görüşme talebini iletebilirsin."
                             data-balloon-pos="right" data-balloon-length="medium"><p style="text-align: right;">Gizli</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="hiddendiv20"></div>
          <div v-if="userProfile.recurring_orders">
            <div class="line"></div>
            <div class="hiddendiv20"></div>
            <div style="margin-bottom: 20px; color: #8b95a1; font-size:14px; width: 100%">
              <div  style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
                <div>
                  <img src="https://gcdn.bionluk.com/site/icon/ic_repeat_customer_gray.svg" style="position: absolute;">
                  <span style="padding-left: 30px;">Tekrarlayan Sipariş</span>
                </div>
                <p style="color: #2d3640; font-size:14px; font-weight:500;">
                  {{userProfile.recurring_orders}} sipariş
                </p>
              </div>
              <div style="float:right; z-index: 999; cursor: help; margin-top:10px;font-size: 12px; color: #2d3640; font-weight: 300;  opacity: 0.8" data-balloon="Bu sayı daha önce sipariş vermiş kullanıcıların, sonraki farklı günlerde tekrar verdikleri sipariş sayısını belirtir. Müşteri memnuniyeti ve hizmet kalitesinin en önemli göstergelerinden biridir."
                   data-balloon-pos="left" data-balloon-length="medium"><p style="text-align: right;">Bu neden önemli?</p>
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
          <div class="line"></div>
          <div class="hiddendiv"></div>
          <div style="display: flex;">
            <div style="color: #8b95a1; font-size:14px;display: inline-block; text-align: left; width: calc(50vw - 15px);">
              <div v-for="info in userProfile.generalInfo" style="margin-bottom: 25px;">
                <div v-if="info.value">
                  <!-- <img :src="$root.CDN_SITE + 'icon/ic_location_on_gray.svg'" style="position: absolute; height: 18px;"> -->
                  <img :src="`${$root.CDN_SITE}${info.icon}`" :style="`position: absolute; height: ${info.iconHeight};  width: ${info.iconWidth};`">
                  <span style="padding-left: 30px;">{{ info.name }}</span>
                </div>
              </div>
            </div>
            <div
              style=" color: #2d3640; font-size:14px; font-weight:500;display: inline-block; text-align: right; width: calc(50vw - 15px); vertical-align: top;">
              <div v-for="info in userProfile.generalInfo">
                <p v-if="info.value" style="margin-bottom: 25px;">
                  {{ convertTime(info) }}
                </p>
              </div>
            </div>

          </div>


          <div v-if="userProfile.isBuyer">
            <div class="line"></div>
            <div style="margin-top: 10px;">
              <div style=" margin-bottom:20px;font-size: 14px; display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
                Alıcı İstatistikleri
              </div>
              <div v-for="info in userProfile.buyerInfo" style="margin-bottom: 25px; color: #8b95a1; font-size:14px; width: 100%">
                <div v-if="info.value" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
                  <div>
                    <!-- <img :src="$root.CDN_SITE + 'icon/ic_location_on_gray.svg'" style="position: absolute; height: 18px;"> -->
                    <img :src="`${$root.CDN_SITE}${info.icon}`"  :style="` opacity:0.6; position: absolute; height: ${info.iconHeight};  width: ${info.iconWidth};`">
                    <span style="padding-left: 30px;">{{ info.name }}</span>
                  </div>
                  <p style=" color: #2d3640; font-size:14px; font-weight:500;">
                    {{ convertTime(info) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="hiddendiv10"></div>
        </div>

      </div>
      <div class="profile-section-body">
        <div style="clear: both"></div>
        <div class="mobileTab">
          <div @click="activeMobileTab = 1" :class="{mobileTabItem: true, active: activeMobileTab === 1}">Hakkında</div>
          <div @click="activeMobileTab = 2" :class="{mobileTabItem: true, active: activeMobileTab === 2 }">İş ilanları</div>
          <div @click="activeMobileTab = 3" :class="{mobileTabItem: true, active: activeMobileTab === 3 }">Yorumlar</div>
        </div>

        <div v-if="activeMobileTab === 1">
          <div class="information-section-2" v-if="portfolios.length && loadedPortfolioPage">
            <div class="information-section-2-label">
              <label class="profile-section-label">Portfolyo</label>
              <router-link :to="'/portfolyo/' + userProfile.username" class="show-all">Tümünü Göster</router-link>
            </div>
            <router-link class="porfolio-div" :to="'/portfolyo/' + userProfile.username">
              <img v-for="(portfolio,index) in portfolios" :src="portfolio.cover.image_url_small"/>
            </router-link>
            <loader v-if="!loadedPortfolioPage && portfolios.length"></loader>
          </div>
          <div style="height: 10px; background-color: #f4f5f7"></div>
          <div class="information-section-3"
               v-if="(userProfile.description ||
                     userProfile.seller_skills.length ||
                     userProfile.educationHistory.length ||
                     userProfile.workHistory.length)">
            <template v-if="userProfile.description">
              <label class="profile-section-label">Açıklama</label>
              <div class="hiddendiv10"></div>
              <div style="color: #8b95a1; font-size: 14px; line-height: 21px;word-wrap: break-word;">
                {{ userProfile.description }}
              </div>
            </template>


            <template v-if="userProfile.seller_skills.length">
              <div class="hiddendiv"></div>
              <div class="line"></div>
              <div class="hiddendiv"></div>
              <label class="profile-section-label">Yetenekler</label>
              <div v-if="user.username === userProfile.username" style="position: absolute;  vertical-align: top; margin-top: -20px; margin-left: 220px;">
                <router-link to="/settings/seller/" class="editButton" style="height: 25px; width: 100px; min-width: 100px; padding: 4px 24px 5px;">Düzenle
                </router-link>
              </div>
              <div class="hiddendiv10"></div>
              <div>
                <span v-for="skill in userProfile.seller_skills" class="skilltagbig">{{ skill.name }}</span>
              </div>
            </template>

            <template v-if="userProfile.educationHistory.length">
              <div class="hiddendiv"></div>
              <div class="line"></div>
              <div class="hiddendiv"></div>
              <label style="font-size: 16px; font-weight: 600;">Eğitim</label>
              <div class="hiddendiv10"></div>

              <div v-for="history in userProfile.educationHistory" style="font-size: 14px;">
                <p>{{ history.school }}</p>
                <p style="color: #bfc8d2; margin-top: 3px;">{{ `${history.department}, ${history.endYear}` }}</p>
              </div>
            </template>
            <template v-if="userProfile.workHistory.length">
              <div class="hiddendiv"></div>
              <div class="line"></div>
              <div class="hiddendiv"></div>
              <label style="font-size: 16px; font-weight: 600;">Projects</label>
              <div class="hiddendiv10"></div>
              <div v-for="history in userProfile.workHistory" style="font-size: 14px;">
                {{ history.title }}
                <p style="color: #bfc8d2;  margin-bottom: 5px;">{{ history.content }}</p>
              </div>
            </template>
          </div>
        </div>

        <div class="gig-section-body" v-if="activeMobileTab === 2">
          <div class="gig-section-label">
            <label>İş İlanları</label>
          </div>
          <loader v-if="!loadedGigPage"></loader>
          <div v-if="!gigs.length && loadedGigPage" class="noGigs"
               style=" font-size: 14px; color: #8b95a1; border: 1px solid #e8e8e8; background-color: white; padding: 50px; text-align: center;">
            {{ userProfile.username }} için gösterilecek hiç İş İlanı yok.
            <div v-if="user.username === userProfile.username && !user.seller.isSeller" style="margin-top: 20px;">
              <router-link to="/freelancer-olmak/">
                <button class="super-button-small">Freelancer Ol!</button>
              </router-link>
            </div>
          </div>

          <div class="profile-gigs-container" v-if="gigs.length">
            <single-gig :impression-u-u-i-d="impressionUUID" v-for="(gig, index) in gigs" :key="index" :item="gig" style="margin-right:25px;" class="gig-content"/>
          </div>

          <div class="see-more-comments" v-if="gigSeemorediv && !gigLoader"
               @click="retrieveGigs(userProfile.username, gigLimit, gigOffset, true)">
            Daha fazla göster +
          </div>
          <div v-if="gigLoader" style="margin: 10px">
            <loader style="width: 30px;height: 30px"></loader>
          </div>
        </div>


        <!--review alani-->
        <div v-if="activeMobileTab === 3" class="deed-detail-reviews">
          <div style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 23px;">
            <h5 class="deed-detail-reviews-header">Değerlendirmeler</h5>
            <div style="display: flex; justify-content: center;align-items: center">
              <select v-model="commentType" class="super-drop-tek-cizgi-review">
                <option class="review-comment-type" value="seller">Freelancer</option>
                <option class="review-comment-type" value="buyer">Alıcı Olarak</option>
              </select>
            </div>
          </div>
          <div class="deed-detail-reviews-info"  v-if="commentInfo.commentRating || commentInfo.commentCount > 0">
            <div style="display: flex;align-items: center">
              <span class="deed-detail-reviews-info-rate" v-if="commentInfo.commentRating">{{ commentInfo.commentRating }}</span>
              <p class="deed-detail-reviews-info-text" v-if="commentInfo.commentCount > 0"><span class="deed-detail-reviews-info-review-count">{{ commentInfo.commentCount }} <span>değerlendirme</span></span>
                <br/><span style="opacity: 0.6" class="deed-detail-reviews-info-review-count">{{ commentInfo.orderCount }} <span>sipariş</span></span>


              </p>

            </div>
          </div>
          <div class="deed-detail-reviews-description" v-if="commentInfo.commentGrades.length">
            <ul class="deed-detail-reviews-description-list">
              <li v-for="grade in commentInfo.commentGrades" class="deed-detail-reviews-description-list-item">
                <div class="deed-detail-reviews-description-list-item-detail">
                  <div style="display: flex">
                    <div class="deed-detail-reviews-description-list-item-detail-image-section">
                      <img class="deed-detail-reviews-description-list-item-detail-image" :src="grade.icon" alt="image">
                    </div>
                    <div class="deed-detail-reviews-description-list-item-detail-text-section">
                    <span class="deed-detail-reviews-description-list-item-detail-text">
                      {{ grade.name }}
                    </span>
                    </div>
                  </div>
                  <div class="deed-detail-reviews-description-list-item-detail-rate">
                    <star-rating
                      v-model="grade.grade"
                      :max-rating="5"
                      :star-size="18"
                      :padding="2.3"
                      :show-rating="false"
                      inactive-color="#bfc8d2"
                      active-color="#ffb700"
                      :read-only="true"></star-rating>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="commentInfo.commentCount > 0" class="deed-detail-comments">
            <a @click="openCommentSection = !openCommentSection" class="deed-detail-comments-link"> {{ commentInfo.commentCount }} yorum</a>
          </div>
          <div v-if="openCommentSection && commentInfo.commentCount" class="deed-detail-comments-container">
            <ul class="deed-detail-comments-list">
              <li v-for="comment in commentInfo.comments" class="deed-detail-comments-list-item">
                <div class="deed-detail-comments-list-item-detail">
                  <div class="deed-detail-comments-list-item-user-container">
                    <div class="deed-detail-comments-list-item-user">
                      <img @click="openUserProfilePage(comment.username)" class="deed-detail-comments-list-item-user-image" :src="comment.avatar_url"
                           alt="user-image">
                      <div style="display: flex;flex-direction: column;padding-left: 11px">
                        <span @click="openUserProfilePage(comment.username)" class="deed-detail-comments-list-item-user-name">{{ comment.username }}</span>
                        <div class="deed-detail-comments-list-item-user-rate">
                          <star-rating
                            v-model="comment.grade"
                            :max-rating="5"
                            :star-size="11"
                            :show-rating="false"
                            inactive-color="#bfc8d2"
                            active-color="#ffb700"
                            :read-only="true"></star-rating>
                        </div>
                      </div>
                    </div>
                    <div class="deed-detail-comments-list-item-user-comment-date">
                      <span class="deed-detail-comments-list-item-user-comment-date">{{ calculateDate(comment.created_at) }}</span>
                    </div>
                  </div>
                  <div class="deed-detail-comments-list-item-detail-comment-container">
                    <p class="deed-detail-comments-list-item-detail-comment-text" v-html="comment.comment"></p>
                  </div>
                  <div class="comment-reply-button"
                       @click="openReplyTextArea(comment.comment_id)"
                       v-if="userProfile.uuid === user.uuid && !comment.comment_reply && !answering[comment.comment_id] || (userProfile.uuid === user.uuid && !comment.comment_reply && answering[comment.comment_id] && !answering[comment.comment_id].answering) ">
                    <p>Yanıtla</p>
                  </div>
                </div>
                <div v-if="comment.comment_reply" class="deed-detail-comments-list-item-reply">
                  <div class="deed-detail-comments-list-item-reply-user-image-container">
                    <img :src="userProfile.avatar_url" alt="user-image" class="deed-detail-comments-list-item-reply-user-image">
                  </div>
                  <div style="padding-left: 11px;width: 100%">
                    <div class="deed-detail-comments-list-item-reply-user-info">
                          <span class="deed-detail-comments-list-item-reply-user-name">
                            {{ userProfile.username }}
                          </span>
                    </div>
                    <div class="deed-detail-comments-list-item-reply-date">
                        <span class="deed-detail-comments-list-item-reply-comment">
                          {{ comment.comment_reply }}
                        </span>
                    </div>
                  </div>
                </div>
                <div class="separator-review" v-if="!comment.comment_reply && answering[comment.comment_id] && answering[comment.comment_id].answering"></div>
                <div class="comment-reply-text-area"
                     v-if="!comment.comment_reply  && answering[comment.comment_id] && answering[comment.comment_id].answering">
                  <img :src="userProfile.avatar_url"/>
                  <textarea v-model="answering[comment.comment_id].commentReply" maxlength="512" minlength="1"
                            placeholder="Yorum yaz..."></textarea>
                </div>
                <div style="display: flex; width: 200px; margin-left: auto">
                  <div class="comment-reply-save-button"
                       @click="cancelCommenting(comment.comment_id)"
                       v-if="!comment.comment_reply  && answering[comment.comment_id] && answering[comment.comment_id].answering">
                    <p>İptal</p>
                  </div>
                  <div class="comment-reply-save-button"
                       @click="sendComment(comment)"
                       v-if="!comment.comment_reply  && answering[comment.comment_id] && answering[comment.comment_id].answering">
                    <p>Gönder</p>
                  </div>
                </div>
              </li>

              <div class="see-more-comments" v-if="!commentSeeMoreLoader && commentSeeMore"
                   @click="getUserCommentsByUser(username, 5, commentOffset, commentType, true)">
                Daha fazla göster
              </div>
              <div v-if="commentSeeMoreLoader" style="margin: 10px">
                <loader style="width: 30px;height: 30px"></loader>
              </div>
            </ul>
          </div>
          <div  style="text-align: center; padding-bottom: 20px; padding-top: 10px; font-size: 14px;" v-if="commentInfo.commentCount <= 0">Bu bölümde henüz bir değerlendirme yok.</div>
        </div>
      </div>

    </div>
  </div>

</template>


<script>
  import singleGig from "@/pages/components/singleGig"
  import { fromNow } from '@/utils/dateFns'

  export default {
    components:{singleGig},
    name: "src-pages-body-profile-mobile-v2_mobile",
    data() {
      return {
        impressionUUID:null,
        loadedPage: false,
        userProfile: {username:null, avatar_url:null},
        followStatus: {},
        uuid: null,
        gigs: [],
        gigOffset: 0,
        gigLimit: 6,
        gigSeemorediv: false,
        gigLoader: false,
        loadedGigPage: false,
        portfolios: [],
        portfolioOffset: 0,
        portfolioLimit: 10,
        portfolioSeemorediv: false,
        portfolioLoader: false,
        loadedPortfolioPage: false,
        username: null,
        disableFollowButton: false,
        pageVersion: "v1",

        // comment
        commentType: "seller",
        commentOffset: 0,
        commentSeeMoreLoader: false,
        commentSeeMore: false,
        answering: {},
        commentInfo: {
          commentGrades: [],
          commentRating: 0,
          commentCount: 0
        },
        allCommentCount: 0,
        showRecurringBuyer: false,
        recurring_buyers: 0,
        openCommentSection: false,
        activeMobileTab: 1
      }
    },

    methods: {

      openUserProfilePage(username) {
        this.$router.push('/' + username);
      },
      preventClicking(event) {
        event.returnValue = false;
        return false;
      },

      specifyPostUrl(ID) {
        return /freelancer-vitrin/ + ID;
      },

      convertTime(info) {
        if (info.name === "Ort. Yanıt Süresi") {
          let timeStamp = info.value;
          let years = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30 / 12;
          if (years >= 1) {
            return years.toFixed(0) + " yıl"
          } else {
            let months = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30;
            if (months >= 1) {
              return months.toFixed(0) + " ay";
            } else {
              let weeks = timeStamp / 1000 / 60 / 60 / 24 / 7;
              if (weeks >= 1) {
                return weeks.toFixed(0) + " hafta";
              } else {
                let days = timeStamp / 1000 / 60 / 60 / 24;
                if (days >= 1) {
                  return days.toFixed(0) + " gün";
                } else {
                  let hours = timeStamp / 1000 / 60 / 60;
                  if (hours >= 1) {
                    return hours.toFixed(0) + " saat"
                  } else {
                    let minutes = timeStamp / 1000 / 60;
                    if (minutes >= 1) {
                      return minutes.toFixed(0) + " dakika";
                    } else {
                      let seconds = timeStamp / 1000;
                      return (seconds >= 1 ? seconds.toFixed(0) : 0) + " saniye";
                    }
                  }
                }
              }
            }
          }
        } else {
          return info.value;
        }
      },

      showPortfolioDetailModal(portfolio) {
        this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: portfolio.uuid}});
      },

      retrieveGigs(username, limit, offset, comingFromSeemoreButton = false) {
        if (comingFromSeemoreButton) {
          this.gigLoader = true;
        }
        this.api.general.getAllGigsByUser(username, limit, offset)
          .then(({data}) => {
            let result = data;
            this.gigLoader = false;
            this.loadedGigPage = true;
            if (result.success) {

              if(this.gigs.length){
                let trackGigObj = [];
                result.data.gigs.forEach((gig, gigdex) => {
                  trackGigObj.push({
                    gig_id:gig.id,
                    seller_id:gig.seller_id,
                    order_id: gigdex + 1
                  })
                });
                let trackingProps = {
                  page:'userprofile',
                  placement:'default',
                  info:trackGigObj,
                  title: this.impressionUUID
                };
                this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
              }


              this.gigs = this.gigs.concat(result.data.gigs);
              this.gigOffset += limit;
              this.gigSeemorediv = result.data.seemorediv;
            } else {
              this.gigLoader = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.gigLoader = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      retrievePortfolios(offset, limit) {
        this.portfolioLoader = true;
        this.api.seller.listPortfolios(this.userProfile.username, this.Constants.PORTFOLIO_STATUSES.ACTIVE, 3, offset)
          .then(({data}) => {
            let result = data;
            this.portfolioLoader = false;
            this.loadedPortfolioPage = true;
            if (result.success) {
              this.portfolios = this.portfolios.concat(result.data.portfolios);
              this.portfolioOffset += limit;
              this.portfolioSeemorediv = result.data.seemorediv;

              if (this.$store.state.showCase.byModal && this.$store.state.showCase.to === this.$store.state.route.from.fullPath) {
                this.$nextTick(() => {
                  this.showPortfolioDetailModal(this.$store.state.showCase.data);
                });
              }
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.portfolioLoader = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      changeGigLikeStatus(receivedGig) {
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.GIG, receivedGig.likeStatus.action, receivedGig.uuid,null,this.$store.state.componentMap.page)
          .then(({data}) => {
            let result = data;

            if (result.success) {
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.revertLikeStatus(receivedGig);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertLikeStatus(receivedGig);
          });
        this.reverseLikeStatus(receivedGig);
      },
      revertLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        }
      },

      reverseLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        }
      },

      reverseFollowStatus(action) {
        if (action === 1) {
          this.followStatus = {
            action: -1,
            actionClass: "unfollow-button",
            actionText: "Takip Ediliyor"
          }
        } else if (action === -1) {
          this.followStatus = {
            action: 1,
            actionClass: "follow-button",
            actionText: "Takip Et"
          }
        }
      },

      followAction(action) {
        this.reverseFollowStatus(action);
        this.disableFollowButton = true;
        this.api.user.updateFollowStatus(this.userProfile.uuid, action, this.$store.state.componentMap.page, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.disableFollowButton = false;

            if (result.success) {
              this.userProfile.followerCount += action;
              this.followStatus = result.data.followStatus;
            } else {
              this.reverseFollowStatus(this.followStatus.action);
              this.$toasted.global.errorToast({description: result.message});
              this.$router.push(result.data.redirect_url);
            }
          })
          .catch(err => {
          	console.log(err);
            this.reverseFollowStatus(this.followStatus.action);
            this.disableFollowButton = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      sendMessage() {
        if (!this.isLoggedIn) {
          this.$router.push('/login/');
        } else {
          this.$router.push('/chat/'+this.userProfile.username);
        }
      },

      retrieveUser() {
        this.api.general.retrieveUser(this.username)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.userProfile = result.data.user;
              this.$store.state.indexHTMLTitle = this.userProfile.meta.title;
              this.$store.state.indexHTMLMetaDescription = this.userProfile.meta.description;
              this.setFacebookMetaProperties(result.data.user.meta.facebookMeta);
              this.followStatus = result.data.followStatus;
              this.loadedPage = true;
              this.adminoLink = result.data.alk;
              this.showRecurringBuyer = result.data.user.show_recurring_buyers;
              this.recurringBuyers = result.data.user.recurring_buyers;
              this.retrieveGigs(this.userProfile.username, this.gigLimit, this.gigOffset);
              this.retrievePortfolios(this.portfolioOffset, this.portfolioLimit);
              this.getUserCommentsByUser(this.username, 5, this.commentOffset, this.commentType, false);

              this.Helper.trackEvents.pageView(this.Helper.createUserPageProps(result.data.user));


            } else {
              this.$store.commit(this.types.SET_COMPONENT_MAP, this.$store.state.routerMap["/*"])
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      makeVoiceCall(conversationInfo) {
        let leftPosition, popUpWidth, popUpHeight, topPosition;
        if (window.screen.width <= 1280) {
          popUpWidth = 960;
          popUpHeight = 540;
        } else {
          popUpWidth = 1280;
          popUpHeight = 720;
        }
        if (popUpWidth > window.innerWidth) {
          leftPosition = 0;
        } else {
          leftPosition = ((window.innerWidth - popUpWidth) / 2);
        }
        if (popUpHeight > window.innerHeight) {
          topPosition = 0;
        } else {
          //50 tabs and default propertios of chrome size
          topPosition = (((window.innerHeight - popUpHeight) / 2) + 50);
        }
        let opener = window.open("", "popup", "width=" + popUpWidth + ",height=" + popUpHeight + ",top=" + topPosition + ",left=" + leftPosition);
        opener.document.write('Cevap Bekleniyor...');
        this.api.chat.checkIsCallable(conversationInfo.username, "audio")
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.api.chat.retrieveCallUserToken(conversationInfo.username, "audio")
                .then(({data}) => {
                  let result = data;
                  if (result.success) {
                    opener.location.href = `/call/${conversationInfo.username}?control=${btoa(JSON.stringify(result.data))}`;
                  } else {
                    opener.close();
                    this.$toasted.global.errorToast({description: result.message});
                  }
                })
                .catch(err => {
                  this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
                });
            } else {
              opener.close();
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      getUserCommentsByUser(username, limit, offset, type, comingFromSeemoreButton = false) {
        if (comingFromSeemoreButton) {
          this.commentSeeMoreLoader = true;
        }
        this.api.general.getUserProfileComments(username, limit, offset, type)
          .then(({data}) => {
            let result = data;
            if (result.success) {

              if (comingFromSeemoreButton) {
                this.commentInfo.comments = [...this.commentInfo.comments.concat(result.data.comments)];
              } else {
                this.commentInfo = result.data;
                this.commentInfo.comments = [...result.data.comments];
              }
              this.commentOffset += limit;
              this.commentSeeMoreLoader = false;
              this.commentSeeMore = result.data.seemorediv;
            } else {
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },


      sendComment(comment) {
        this.api.seller.replyComment(comment.comment_id, this.answering[comment.comment_id].commentReply)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              comment.comment_reply = this.answering[comment.comment_id].commentReply;
              let newCommentInfo = Object.assign({}, this.commentInfo);
              this.commentInfo = newCommentInfo;
            } else {
              this.answering[comment.comment_id].commentReply = "";
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.answering[comment.comment_id].commentReply = "";
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      openReplyTextArea(commentId) {
        let clone = Object.assign({}, this.answering);
        clone[commentId] = {};
        clone[commentId].answering = true;
        clone[commentId].commentReply = "";
        this.answering = clone;
      },

      cancelCommenting(commentId) {
        let clone = Object.assign({}, this.answering);
        clone[commentId].answering = false;
        clone[commentId].commentReply = "";
        this.answering = clone;
      },

      calculateDate(exDate) {
        return fromNow(exDate)
      },



      goPortfolioPage() {
        this.$router.push('/portfolyo/' + this.userProfile.username);
      }
    },

    watch: {
      '$store.state.routerParams': function (newVal, oldVal) {
        if (newVal && newVal.length) {
          Object.assign(this.$data, this.$options.data());
          this.username = newVal[0];
          this.loadedPage = false;
          this.retrieveUser(this.username);
        }
      },
      activeMobileTab: function (newVal){

        if(newVal === 2 && this.gigs.length > 0){
          let trackGigObj = [];
          this.gigs.forEach((gig, gigdex) => {
            trackGigObj.push({
              gig_id:gig.id,
              seller_id:gig.seller_id,
              order_id: gigdex + 1
            })
          });
          let trackingProps = {
            page:'userprofile',
            placement:'default',
            info:trackGigObj,
            title: this.impressionUUID
          };
          this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
        }
      },

      commentType(newVal, oldVal) {
        if (newVal === "buyer") {
        } else {
          this.commentOffset = 0;
        }
        this.commentOffset = 0;
        this.getUserCommentsByUser(this.username, 5, this.commentOffset, this.commentType, false)
      }
    },

    created() {
      this.impressionUUID = this.Helper.generateUUID();

      this.EventBus.$on('modalPortfolioUpdated', (payload) => {
        let portfolio = this.portfolios.find(({ uuid }) => uuid === payload.portfolioUuid)
        portfolio.comments.commentCount = payload.comments.commentCount;
        portfolio.fav_count = payload.favCount;
      });

      this.username = this.$store.state.routerParams[0];
      this.retrieveUser(this.username);
    },

    beforeDestroy() {
      this.EventBus.$off('modalPortfolioUpdated');

    }
  }

</script>

<style scoped lang="scss">
  .appBar {
    z-index: 99;
    background-color: #fff;
  }

  .header-title {
    font-size: 16px;
    font-weight: 600;
    color: #4b4f52;
    width: 80%;
    text-align: center;
  }

  .profile-page-body {
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow-x: scroll;
    background-color: #f4f5f7;
  }

  /*information*/
  .information-body {
    display: inline-block;
    width: 100vw;
    text-align: center;
  }

  .information-section-1 {
    background-color: white;
    padding: 38px 15px 0;
    width: calc(100vw - 30px);
  }

  .admino-link-div {
    position: absolute;
    vertical-align: top;
    margin-top: 30px;
    margin-left: 10px;
    .admino-link {
      color: #FFF;
      background-color: #fd4056;
      border: 0;
      padding: 4px 6px;
      margin-top: 10px;
      font-size: 12px;
    }
  }

  /* buttons*/

  .button-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }

  .follow-button {
    cursor: pointer;
    text-shadow: none !important;
    width: 145px;
    height: 44px;
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.2);
    background: #fff url(https://gcdn.bionluk.com/site/icon/tansel_follow_ic.svg) no-repeat left !important;
    background-position-x: 34px !important;
    min-width: 89px;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: #2d3640;
    &:hover {
      background: #2d3640 url(https://gcdn.bionluk.com/site/icon/tansel_follow_ic_white.svg) no-repeat left !important;
      background-position-x: 34px !important;
      color: #fff;
    }
  }

  .unfollow-button {
    width: 145px;
    height: 44px;
    border-radius: 2px;
    background: #e1e6ed url(https://gcdn.bionluk.com/site/icon/tansel_following_ic.svg) no-repeat left !important;
    background-position-x: 15px !important;
    border: none;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: #2d3640;
    &:hover {
      background: #2d3640 url(https://gcdn.bionluk.com/site/icon/tansel_unfollow_ic.svg) no-repeat left !important;
      background-position-x: 15px !important;
      color: #fff;
      .unfollow {
        display: block;
        margin-left: 22px;
      }
      .follow-button-text {
        display: none;
      }
    }
  }

  .follow-button-text {
    margin-left: 22px;
  }

  .unfollow {
    display: none;
  }

  .send-message-button {
    width: 145px;
    height: 44px;
    border-radius: 2px;
    background-color: #e63262;
    padding: 0.3em 1.5em .5em;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    margin-left: 10px;
    border: none;
    &:hover {
      background-color: #c8325f !important;
    }
  }

  .followers-div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 16px;
    &:hover {
      text-decoration: underline;
    }
    .followers-count {
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      color: #2d3640;
      margin-left: 6px;
    }
  }

  .star-rating-s15 {
    background-image: url(//assetsv2.fiverrcdn.com/assets/v2_globals/icn-master-ratings-stars-58c680be892eedb44ed303cfb40b7777.svg), url(//assetsv2.fiverrcdn.com/assets/v2_globals/icn-master-ratings-stars-58c680be892eedb44ed303cfb40b7777.svg), url(//assetsv2.fiverrcdn.com/assets/v2_globals/icn-master-ratings-stars-58c680be892eedb44ed303cfb40b7777.svg), url(//assetsv2.fiverrcdn.com/assets/v2_globals/icn-master-ratings-stars-58c680be892eedb44ed303cfb40b7777.svg), url(//assetsv2.fiverrcdn.com/assets/v2_globals/icn-master-ratings-stars-58c680be892eedb44ed303cfb40b7777.svg);
    background-repeat: no-repeat;
    background-size: 15px 45px;
    display: inline-block;
    overflow: hidden;
    width: 91px;
    height: 15px;
    text-indent: -3000px;
  }

  .star-rating-s15.rate-10 {
    background-position: top left, top left 19px, top left 38px, top left 57px, top left 76px;
  }

  .numbercircle30 {
    border-radius: 50%;

    width: 20px;
    height: 20px;
    padding: 6px;
    text-align: center;
    font-size: 18px;
  }

  .profile-section-label {
    font-size: 16px;
    font-weight: 600;
    color: #242424;
  }

  /*section 2*/
  .information-section-2 {
    background-color: white;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    .information-section-2-label {
      margin-bottom: 23px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 15px;
      padding-left: 15px;
      .show-all {
        font-size: 14px;
        color: #2d3640;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .gig-section-body {
    padding-top: 25px;
    .gig-section-label {
      margin-bottom: 23px;
      padding-left: 15px;
      label {
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #2d3640;
      }
    }
  }

  .porfolio-div {
    overflow-x: scroll;
    display: flex;
    img {
      width: 33%;
      height: auto;
      margin-left: 0;
      &:first-child {
        margin-left: 15px;
      }
    }
  }

  /*section 3*/
  .information-section-3 {
    background-color: white;
    padding: 30px 15px;
    text-align: left;
    width: calc(100vw - 60px);
  }

  /*page body */
  .profile-section-body {
    width: 100vw;
    background-color: #fff;
  }

  .mobileTab {
    padding-top: 23px;
    padding-bottom: 20px;
    background-color: #f4f5f7;
    display: flex;
    height: 46px;
    align-items: flex-end;
    align-self: baseline;
  }

  .mobileTabItem {
    flex: 1;
    font-weight: normal;
    text-decoration: none;
    font-size: 16px;
    color: #8b95a1 !important;
    padding-bottom: 13px;
    text-align: center;

  }

  .mobileTabItem.active {
    font-weight: normal;
    border-bottom: 3px #fd4056 solid;
    color: #fd4056 !important;
  }

  .tekligig {
    display: inline-block;
    margin-bottom: 40px;
    width: 230px;
  }

  .gigsflex {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    width: 780px;
  }

  .gigsflexitem {
    width: 230px;
    margin-left: 25px;
    margin-bottom: 20px;
    height: 225px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
  }

  .portfolioflexitem {
    width: 230px;
    margin-left: 25px;
    margin-bottom: 20px;
    height: 200px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
  }

  .marginleft27 {
    margin-left: 27px;
  }

  .user-profile-level {
    position: absolute;
    right: -5px;
    bottom: -5px;
    z-index: 2;
  }

  .play-icon {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 5%;
    top: 10%;
    transform: translate(-10%, -5%);
    border: 2px solid #FFF;
    border-radius: 50%;
    background-color: #FFF;
  }

  /*yeni sayfa */

  .profile-gigs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    overflow-y: scroll;
    padding-bottom: 30px;
  }

  .profile-gig-body {
    margin-left: 10px;
    &:first-child {
      margin-left: 15px;
    }

    display: inline-block;
    width: 240px;
    height: 296px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    vertical-align: top;
    text-align: left;
  }

  .profile-gig-userinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    margin-top: 10px;
  }

  .user-info-rating-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
  }

  .bounce-enter-active {
    animation: bounce-in .5s;
  }

  .bounce-leave-to {
    animation: bounce-out .0s;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes bounce-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  /*Review */

  .deed-detail-reviews {
    padding: 25px 15px;
  }

  .deed-detail-reviews-header {
    font-size: 16px;
    font-weight: 600;
    color: #2d3640;
  }

  .review-comment-type {
    font-size: 14px;
    text-align: left;
    color: #4b4f52;
  }

  .super-drop-tek-cizgi-review {
    background-color: #ffffff;
    font-size: 14px;
    color: #4b4f52;
    text-align: center;
    border: none;
    border-bottom: solid 1px #ccd3d3;
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: 110px;
    -moz-appearance: none;
    min-width: 130px;
    border-radius: 2px;
    background-color: #fefdfd;
    padding: 1px 5px 8px 10px;
    font-family: 'sofia-pro';
  }
  .deed-detail-reviews-info {
    background-color: #f4f5f7;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    height: 50px;
  }

  .deed-detail-reviews-info-rate {
    font-size: 32px;
    color: #ffb700;
  }

  .deed-detail-reviews-info-text {
    font-size: 14px;
    color: #939c9c;
    padding-left: 10px;
  }

  .deed-detail-reviews-info-review-count {
    color: #242424;
  }

  .deed-detail-reviews-description-list {
    display: flex;
    flex-direction: column;
    padding-top: 19px;
    padding-bottom: 5px;
  }

  .deed-detail-reviews-description-list-item {
    padding-bottom: 25px;
  }

  .deed-detail-reviews-description-list-item-detail {
    display: flex;
    justify-content: space-between;
  }

  .deed-detail-reviews-description-list-item-detail-image {
    padding-right: 15px;
  }

  .deed-detail-reviews-description-list-item-detail-text {
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #242424;
  }

  .deed-detail-reviews-description-list {
    display: flex;
    flex-direction: column;
    padding-top: 19px;
    padding-bottom: 5px;
  }

  .deed-detail-reviews-description-list-item-detail {
    display: flex;
    justify-content: space-between;
  }

  .deed-detail-reviews-description-list-item-detail-image {
    padding-right: 15px;
  }

  .deed-detail-reviews-description-list-item-detail-text {
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #242424;
  }

  .deed-detail-comments {
    padding-top: 2px;
    padding-bottom: 8px;
  }

  .deed-detail-comments-link {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #2d3640;
  }

  .deed-detail-comments-list-item-reply-user-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0;
  }

  .deed-detail-comments-list-item-reply-user-name {
    color: #242424;
  }

  .deed-detail-comments-list-item-reply-comment {
    color: #8b95a1;
    padding-top: 2px;
    word-break: break-word;
  }

  .deed-detail-comments-list-item-reply-user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .deed-detail-comments-list-item-reply-date-text {
    color: #8b95a1;
    padding-top: 2px;
  }

  .deed-detail-comments-list-item-reply {
    padding: 10px 0;
    margin-left: 40px;
    font-size: 14px;
    display: flex;
    border-top: 1px solid #e6eaea;
    align-items: flex-start;
  }

  .deed-detail-comments-list-item {
    border-bottom: 1px solid #e6eaea;
    &:last-child {
      border-bottom: none;
    }
  }

  .deed-detail-comments-list-item-detail {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  .deed-detail-comments-list-item-user-container {
    display: flex;
    justify-content: space-between;
  }

  .deed-detail-comments-list-item-user {
    display: flex;
  }

  .deed-detail-comments-list-item-user-image {
    width: 30px;
    height: 30px;
    border-radius: 100px;
  }

  .deed-detail-comments-list-item-user-name, .deed-detail-comments-list-item-user-comment-date {
    font-size: 14px;
    color: #242424;
  }

  .deed-detail-comments-list-item-user-comment-date {
    color: #8b95a1;
  }

  .deed-detail-comments-list-item-detail-comment-text {
    font-size: 14px;
    color: #8b95a1;
  }

  .deed-detail-comments-list-item-detail-comment-container {
    padding-left: 40px;
  }

  .deed-detail-comments-list-item-detail-comment-text {
    word-break: break-word;
  }

  .see-more-comments {
    font-size: 14px;
    font-style: italic;
    cursor: pointer;
    text-decoration: underline;
    color: #e63262;
    vertical-align: middle;
    line-height: 60px;
    text-align: center;
  }


  /*loader part*/
  @keyframes loading {
    10% {
      opacity: .4;
    }
    2% {
      opacity: .1;
    }
  }

  .profile-loader {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .information-section-1-loader-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #dfe4ec;
  }

  .information-section-1-loader-username {
    margin-top: 10px;
    width: 60px;
    height: 16px;
    background-color: #dfe4ec;
  }

  .information-section-1-loader-title {
    margin-top: 10px;
    width: 80px;
    height: 13px;
    background-color: #dfe4ec;
  }

  .information-section-1-loader-stars {
    margin-top: 10px;
    width: 100px;
    height: 14px;
    background-color: #dfe4ec;
  }

  .followers-div-loader {
    margin-top: 10px;
    width: 60px;
    height: 16px;
    background-color: #dfe4ec;
  }

  .general-info-icon-loader {
    margin-bottom: 10px;
    width: 120px;
    height: 16px;
    background-color: #dfe4ec;
  }

  .general-info-loader {
    margin-bottom: 10px;
    width: 80px;
    height: 16px;
    background-color: #dfe4ec;
  }


  .information-section-3-description-loader {
    margin-top: 10px;
    width: 100%;
    height: 14px;
    background-color: #dfe4ec;
    margin-bottom: 10px;
  }

  .information-section-3-loader-separator {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 0;
    border-top: solid 1px #dfe4ec;
  }

  .information-section-3-edu-loader {
    margin-top: 15px;
    width: 200px;
    height: 18px;
    background-color: #dfe4ec;
  }

  .information-section-3-cv-loader {
    margin-top: 3px;
    width: 250px;
    height: 14px;
    background-color: #dfe4ec;
  }

  .portfolio-loader {
    width: 100%;
    height: 70px;
    background-color: #dfe4ec;
  }

</style>
